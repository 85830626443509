@import "../variables"
.default-theme
    .symbol-error
        display: none
    .input-item
        color: $charcoal-gray
        .title
            color: $charcoal-gray
        .input-wrap
            .input
                &::placeholder
                    color: $concrete-gray
                &.read-only, &[disabled]
                    background-color: $transparent-black-07
                    color: $dull-black
                    opacity: 0.8
                &:focus
                    background-color: $light-blue
        .error
            color: $crimson-red
        &.focus
            .title
                color: $sky-blue
            .input
                background-color: $light-blue
                border-bottom: 1px solid $sky-blue
        &.has-error
            .error
                color: $crimson-red
            .input
                border-bottom: 1px solid $crimson-red
                background-color: $light-pink
            .title
                color: $crimson-red
