@import "../variables"
@import "../../../mixins"

.nova-theme
    .main-header
        +justifySpaceBetween
        border-bottom: 1px solid $pearl-gray
        padding: 10px 30px 10px
        background-color: $basic-white
        +media-max-width($breakpoint-699)
            padding: 10px
        .main-method
            color: $matgray
            font-size: $font-md
            text-transform: uppercase
            font-weight: bold
            width: 100%
            padding: 0 10px
            +media-max-width($breakpoint-400)
                font-size: $font-2xs
        .header-customer
            white-space: nowrap
            +media-max-width($breakpoint-400)
                font-size: $font-xs
            .header-username
                color: $charcoal-gray
                text-decoration: none
                cursor: pointer
                position: relative
                +tr(0.25)
                margin-left: 5px
                &:after
                    content: ''
                    position: absolute
                    left: 0
                    bottom: 0
                    transform: scale(1)
                    width: 100%
                    height: 1px
                    background: $transparent-black-20
                    +tr(0.25)
                &:hover
                    &:after
                        transform: scale(0)
        .header-logo
            img
                width: 48px
                max-width: 100%
    .title-container
        text-align: center
        padding: 15px
        background: $basic-white
        margin: 0 -20px
        position: relative
        +media-max-width($breakpoint-699)
            margin: 0 -10px
        .back
            font-size: 0
            position: absolute
            left: 15px
            top: 50%
            transform: translateY(-20%)
            cursor: pointer
        .title-text
            font-size: $font-md
            .img-icon-wrapper
                display: none
    .main-header.header-virtual_terminal
        background-color: $cherry-red
        .header-customer .header-username
            color: $basic-white
