@import "../variables"
@import "../../../mixins"

.supreme-theme
    .button, a.button
        min-width: 200px
        height: 56px
        border-radius: 32px
        background-color: $coral
        border: 2px solid $coral
        color: $pale-blue
        box-shadow: 0 1px 2px $transparent-black-20
        font-size: $font-3xl
        opacity: 1
        +media-max-width($breakpoint-699)
            font-size: $font-md
        &:hover
            background-color: darken($coral, 5%)
            opacity: 1
        &.disabled
            background-color: $current
            border: 2px solid $current
            opacity: 1
            &:hover
                opacity: 1
        &.button-outline
            background-color: transparent
            border-color: $coral
            color: $coral
            margin: 15px auto
            &:hover,
            &.disabled
                opacity: 0.8
                +tr(0.25)
