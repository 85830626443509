@import "../../../mixins"

.dark-theme
    .manual-bank-wire-page
        .manual-bank-wire-page-title
            width: 100%
            height: 20px
            font: normal normal normal 15px/20px Open Sans
            letter-spacing: 0
            color: $basic-white
            margin-top: 2px
            margin-bottom: 3px

        .bank-items-container
            padding: 5px 20px 5px 5px
            +media-max-width($breakpoint-699)
                width: 100%
                max-width: none
                padding: 0

        .amount-wrapper
            padding-top: 7px
            +media-max-width($breakpoint-699)
                padding-top: 5px
            .input
                height: 50px

        .bank-item
            background-color: $charcoal-gray
            border: 1px solid $dark-theme-gray
            border-radius: 4px

            &.selected
                border: 1px solid $current

            .title
                font: normal normal normal 17px/23px Open Sans
                letter-spacing: 0px
                color: $basic-white

            .selected-icon
                background-color: $current

                .checkbox-arrow-svg
                    fill: $basic-black