@import url('../../../fonts/fontawesome5.10.2/css/all.min.css')
@import "basic"
@import "components/header"
@import "pages/gateways-page"
@import "pages/payment-page"
@import "pages/redirect-page"
@import "pages/partially-approved-page"
@import "pages/retry-page"
@import "pages/basic-open-banking-page"
@import "avatrade-buttons"
@import "components/avatrade-footer"
@import "avatrade-modal"
@import "pages/error-page"
@import "components/input-wrapper"
@import "components/amount-container"
@import "components/button"
@import "components/modal"
@import "components/pay-fields-container"
@import "components/checkbox"
@import "pages/manual-bank-wire"
