@import "../variables"
@import "../../../mixins"

.nova-theme
    .button
        background-color: $spring-green
        border-radius: 30px
        border: 1px solid $spring-green
        margin: 20px auto
        min-width: 220px
        +media-max-width($breakpoint-400)
            min-width: 150px
            padding: 10px 10px
        &:hover
            background-color: transparent
            color: $spring-green
        &.disabled
            cursor: default
            background-color: $medium-gray
            border-color: $medium-gray
            &:hover
                color: $basic-white
        &.button-outline
            background-color: transparent
            border: 1px solid $spring-green
            color: $spring-green
            &:hover
                background-color: $spring-green
                color: $basic-white
            &.disabled
                background-color: transparent
                border-color: $medium-gray
                color: $medium-gray
                &:hover
                    color: lighten($medium-gray, 10%)
                    border-color: lighten($medium-gray, 10%)
    .amount-page
        .button
            background-color: $azure-blue
            border-color: $azure-blue
            &:hover
                background-color: $basic-white
                color: $azure-blue
            &.disabled
                cursor: default
                background-color: $medium-gray
                border-color: $medium-gray
                &:hover
                    color: $basic-white
    .result-container
        .button-wrapper
            display: flex
            flex-wrap: wrap
            .button
                font-size: $font-md
                margin: 10px auto
