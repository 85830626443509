@import "variables"
@import "../../mixins"

.default-theme
    font-family: 'Open Sans', sans-serif
    color: $charcoal-gray
    &.wrapper
        min-height: 300px
        height: auto
        +media-max-width($breakpoint-800)
            padding: 0
        .wrap
            box-shadow: 0 4px 8px 0 $transparent-black-30
            +tr(0.25)
            max-width: 100%
            width: 800px
            min-height: auto
            border-radius: 8px
            padding: 15px 30px
            +media-max-width($breakpoint-800)
                min-height: 100vh
                box-shadow: none
                border: 0
                padding: 15px 15px 30px
            +media-max-width($breakpoint-400)
                padding: 15px 10px 20px
            +media-max-width($breakpoint-360)
                padding: 15px 8px 20px
        .slider-container
            background-color: $basic-white
    .form-container form
      padding: 0 5px
    .title-container
        color: $ocean-blue
    footer
        padding: 0 30px
        font-size: $font-5xs
        color: $neutral-gray
        +media-max-width($breakpoint-699)
            padding: 0 5px
        .copyright
            font-size: $font-4xs
            color: $neutral-gray
    .custom-html
        display: flex
        width: 100%
        justify-content: center
        .pay-fields-container
            padding: 10px 20px!important
    .title-text
        text-transform: uppercase
        color: $ocean-blue
        letter-spacing: 0.7px
        .arrow-wrapper
            display: none
    .slick-prev:before,.slick-next:before
        color: $transparent-black-15
    .custom-html
        .pay-fields-container
            width: 100%
            max-width: 100%
            padding: 0
    .fee-container
        margin: 3px 10px 20px
        .fee-container
            margin: 0 10px 0 10px
            +media-max-width($breakpoint-699)
                margin: 0 5px 0 5px
    .multiple-select-container
        .multiple-separator
            background-color: $transparent-foggy-gray-50
        .multiple-options
            background-color: $light-blue
        .multiple-dropdown-container
            .svg-dropdown
                path
                    fill: $transparent-foggy-gray-50
            &:hover
                background-color: $transparent-pearl-31
    .custom-select-container
        .custom-select-dropdown-arrow-container
            .svg-dropdown
                path
                    fill: $nickel-gray
            &:hover
                background-color: $transparent-pearl-50
        .custom-select-options-container
            background-color: $light-blue
    .pci-container
        .pci-card-holder-input
            &:focus
                background-color: $light-blue
                border-bottom: 1px solid $sky-blue
            &.has-error
                border-bottom: 1px solid $crimson-red
                background-color: $light-pink
            &[disabled]
                color: $dull-black
        .pci-card-holder
            &.focus
                .pci-card-holder-input
                    &.has-error
                        background-color: $light-blue
                        border-bottom: 1px solid $sky-blue
        .input-item
            .error
                color: $crimson-red
    .arrow-wrapper
        svg
            g path
                fill: $sky-blue
