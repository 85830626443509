.nova-theme
    .error-page
        position: relative
        padding: 10px
        width: 100%
        .back
            position: absolute
            top: 15px
            left: 15px
            cursor: pointer

