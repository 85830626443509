@import "../../../mixins"

.nova-theme
    .col-wrapper
        display: flex
        flex-wrap: wrap
        +media-max-width($breakpoint-699)
            flex-direction: column
        .pay-fields-container
            padding: 0px
            width: 100%
            max-width: none
        .pay-fields-container
            order: 1
        .amount-wrapper
            order: 4
        .checkbox-container
            order: 2
        .credit-add-container
            order: 3
