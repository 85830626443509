@import "../variables"

.dark-theme
    .select-options-row
        .loader-container
            .loader
                margin-top: 12px
                +media-max-width($breakpoint-360)
                    margin-top: 22px
        .select-options-placeholder
            &:before
                top: 0
                padding: 5px 6px
                color: $basic-white