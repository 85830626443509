@import "variables"
@import "../../mixins"
.nova-theme
    .slider-amount-wrapper
        .currency-select
            -moz-appearance: none
            -webkit-appearance: none
            border-radius: 0
            padding: 0
            font-family: 'Muli', sans-serif
            display: block
            +tr(0.25)
            max-width: 100%
            box-sizing: border-box
            border: 0
            outline: none
            height: 35px
            border-bottom: 1px solid $transparent-black-25
            background: transparent
            font-size: $font-md
            color: $graphite-gray
            font-weight: bold
            width: 100%
            margin: 0


