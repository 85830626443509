=center
    display: flex
    justify-content: center
    align-items: center

=justifySpaceBetween
    display: flex
    justify-content: space-between
    align-items: center

=padding
    padding: 2px 7px

=tr($tr:0.35)
    transition: $tr+s all ease

