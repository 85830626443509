@import "../variables"

.dark-theme
    .window-redirect-container
        background: url("../../../../images/bg_cirlcles_dark.png") $charcoal-gray no-repeat bottom
        background-size: contain
        .content-text
            color: $basic-white
        &.polling-middle-page
            .loader-container
                background-color: transparent