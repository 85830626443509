@import "../../../../../shared-styles"
@import "../../variables"

$yellow: #FEDF00
$silver: #E3E3E3
$silverdark: #909090
$dullblue: #065f95
$coral: #FB6E54
$dullgray: #b1b1b1
$current: #FFAF9F
$purple: #abb7d4
$transparent-pearl-51: rgba(238, 238, 238, 0.51)
