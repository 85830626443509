//variables
@import "../../../assets/styles/variables"
@import "../../Footer/footer"

body:has(.panoramic-2-0-theme)
  color: var(--neutral_12)
  font-family: $font-family
  font-size: $font-size
  line-height: 1.2
  font-weight: 400

.panoramic-2-0-theme
  &.wrapper
    display: flex
    height: 100vh
    flex-direction: column
    align-items: center
    justify-content: flex-start
    padding: 0
    background-color: var(--neutral_1)
    &.iframe-container
      background-color: transparent
      height: auto
    .wrap
      padding: 50px 60px 25px
      background-color: var(--main_0)
      min-height: 700px
      height: 700px
      max-height: 100%
      width: 100%
      max-width: 875px
      min-width: 320px
      overflow: auto
      color: inherit
      font-family: inherit
      font-size: inherit
      line-height: inherit
      font-weight: inherit
      border: none
      +media-max-width($breakpoint-480)
        min-width: 290px
        padding: 16px
      &:has(.main-header)
        padding: 8px 60px 25px
        +media-max-width($breakpoint-699)
          padding: 25px 30px
        +media-max-width($breakpoint-480)
          padding: 25px 10px
    .main-container
      position: relative
      display: flex
      flex-direction: column
      align-items: center
      gap: 32px
      justify-content: space-between
      padding: 0
      width: 100%
      height: 100%
      max-width: 100%
      min-width: 100%
      background-color: transparent
      border: none
      & > *
        width: 100%
        max-width: 100%
