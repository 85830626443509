@import "../../assets/styles/variables"
@import "../../../shared-styles"
    
.panoramic-2-0-theme
    .error-container
        width: 100%
        position: relative
        display: flex
        flex-direction: column
        justify-content: space-between
        align-items: center
        font-family: var(--font_family)
        .error-back-button
            height: 20px
            display: flex
            justify-content: flex-start
            width: 100%
            .back
                font-size: $font-sm-relative
                display: flex
                align-items: center
                white-space: nowrap
                gap: 8px
                cursor: pointer
                svg
                    width: 20px
                    height: 20px
        .error-page, .not-found-page
            height: 500px
            display: flex
            justify-content: flex-start
            flex-direction: column
            align-items: center
            max-width: 620px
            font-style: normal
            div
                margin-top: 20px
                margin-bottom: 20px
                text-align: center
                color: $mellow-gray
            .title
                font-size: $font-2xl-relative
                font-weight: 600
                color: var(--neutral_12)
            .description
                color: var(--neutral_12)
                font-size: $font-sm-relative
                padding: 60px 0 48px
                text-align: left
            a
                z-index: 1
            &.error-9
                .error-message
                    display: block
                    margin-top: 15px
                    font-size: $font-xl-relative
                    span
                        font-weight: bold
            .error-icon
                height: 50px
                margin: 0
