@import "../../../mixins"

.classic
    .manual-bank-wire-page
        .manual-bank-wire-page-title
            width: 100%
            height: 20px

            font: normal normal normal 15px/20px Open Sans
            letter-spacing: 0
            color: $classic-theme-blue
            padding-left: 10px
            margin-bottom: 5px

        .bank-items-container
            max-width: 100%
            padding: 0
            margin-bottom: 40px

            .bank-items-list
                max-height: calc(65vh - 140px)

        .amount-wrapper
            width: 100%
            max-width: 100%
            .input-item
                flex-grow: 1

        .bank-item
            background: $ghost-white 0% 0% no-repeat padding-box
            border: 1px solid $platinum-gray

            &.selected
                background: $current 0% 0% no-repeat padding-box
                border: 1px solid $platinum-gray

                .title
                    color: $basic-white

            .title
                font: normal normal normal 15px/20px Open Sans
                letter-spacing: 0
                color: $dark-gray

            .selected-icon
                background-color: $basic-white

                .checkbox-arrow-svg
                    fill: $current