@import "../../../mixins"

.classic
    .error-container
        width: 100%
        height: 100%
        position: relative
        .error-page, .not-found-page
            height: 500px
            flex-direction: column
            +center
            font-size: $font-6xl
            font-style: italic
            div
                margin-top: 20px
                margin-bottom: 20px
                text-align: center
            .back
                position: absolute
                left: 0
                top: 15px
                transform: translateY(-50%)
                font-size: $font-2xs
                cursor: pointer
                z-index: 1
                &:hover
                    text-decoration: underline
