@import "../../../shared-styles"
    
.back-to-card
    &__wrapper
        display: flex
        justify-content: flex-end
        padding-top: 10px

        .panoramic-2-0-theme &
            padding-top: 0 
            display: flex
            justify-content: space-between

        .nova-theme &
            background-color: $basic-white
            padding-right: 35px
            +media-max-width($breakpoint-699)
                padding-right: 15px

        .classic &
            padding-right: 10px

        .power-slide &
            margin-left: auto
            padding: 15px 0 0

    &__button
        display: inline-flex
        cursor: pointer

        .panoramic-2-0-theme &
            display: flex
            align-items: center
            color: var(--neutral_active)
            font-weight: 400
            background: none
            border: none
            padding: 0
            font-size: $font-md
            line-height: 1.4
            cursor: pointer
            svg
                display: inline-block
                vertical-align: bottom
                transform: rotate(90deg)
                width: 25px

        &._disabled
            pointer-events: none

            .back-to-card__text
                .panoramic-2-0-theme &
                    padding-left: 25px

    &__text
        padding-left: 30px
        font-weight: 700

        .panoramic-2-0-theme &
            padding: 0
            font-weight: 400