@import "../variables"

.classic
    .button, a.button
        border: 1px solid transparent
        background-color: $current
        margin: 10px auto
        &:hover
            background-color: darken($current, 5%)
        &:active
            background-color: darken($current, 7%)
        &.disabled
            background-color: gray
        &.button-outline
            background-color: transparent
            color: $current
            border: 2px solid $current
            &:hover,
            &:active
                color: darken($current, 5%)
                border-color: darken($current, 5%)
            &.disabled
                color: gray
                border-color: gray
