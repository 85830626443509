@import "../../../mixins"
@import "../variables"

.supreme-theme
    .billing-info-container
        .billing-info
            color: white
            border: 1px solid $basic-white
            border-radius: 18px
            +media-max-width($breakpoint-699)
                margin-bottom: 20px
        .billing-info-error
            color: $crimson-red
