@import "../variables"
@import "../../../mixins"
.supreme-theme
    .amount-wrapper
        border-radius: 10px
        background-color: transparent
        width: 230px
        .promocode-title-container
            margin-bottom: 15px
        .btn-wrapper
            padding: 0
            display: flex
            margin-top: 10px
            position: absolute
            right: 40px
            bottom: -25px
            max-width: 250px
            +media-max-width($breakpoint-699)
                width: 100%
                max-width: 100%
                right: 0
                left: 0
                bottom: -25px
        .current-currency
            color: $basic-white
        .checkbox-accept-container
            color: $basic-white
        .fee-container
            margin: 5px 10px 7px
            padding: 10px
            background-color: $basic-white
            box-shadow: none
            color: $charcoal-gray
            border-radius: 5px
            +media-max-width($breakpoint-699)
                position: static
                margin: 0 5px 30px 5px
                padding: 5px
            .total
                color: $basic-black
