@import "../variables"

.nova-theme
    .input-item
        .input
            color: $graphite-gray
            font-weight: 700
            border-bottom: 1px solid $transparent-black-25
            &::placeholder
                font-size: $font-md
                opacity: 0.7
                font-weight: normal
            &.read-only, &[disabled]
                background-color: $transparent-black-10
                color: $transparent-charcoal-75
                border-bottom: none
                cursor: default
        .title
            font-size: $font-3xs
            color: $softgray
            text-transform: uppercase
        &.has-error
            border-bottom: $ruby-red
            .title
                color: $ruby-red
            .input
                border-bottom: 1px solid $ruby-red
            .input-wrap
                &:before
                    content: "\f071"
                    position: absolute
                    bottom: 10px
                    font-family: 'Font Awesome 5 Free'
                    font-weight: 900
                    right: 30px
                    z-index: 1
                    color: $ruby-red
    .credit-add-container
        .input-wrap
            position: relative
            &:after
                content: "\f002"
                position: absolute
                left: 2px
                top: 50%
                transform: translateY(-50%) rotate(45deg)
                font-family: 'Font Awesome 5 Free'
                font-weight: 900
                font-size: $font-lg
                color: $azure-blue
            .input
                padding-left: 30px
            .title
                padding-left: 30px
