@import "../variables"
@import "../../../mixins"

.supreme-theme
    .page-container.basic-open-banking-page
        .open-banking-content
            .open-banking-option:hover
                border-color: $current

            .open-banking-option.selected
                border-color: $current