@import "../../../mixins"

$amount-width: 260px

.default-theme
    .col-wrapper
        .pay-fields-container
            max-width: calc(100% - #{$amount-width})
        .amount-wrapper
            min-width: $amount-width
            width: $amount-width

    .col-wrapper
        display: flex
        flex-wrap: wrap
        +media-max-width($breakpoint-699)
            flex-direction: column
        .pay-fields-container
            padding: 10px
            padding-right: 30px
            width: 100%
            +media-max-width($breakpoint-699)
                width: 100%
                max-width: none
                padding: 10px 5px
        .amount-wrapper
            +media-max-width($breakpoint-699)
                padding: 10px 5px
                width: 100%
                min-width: auto
