@import "variables"
@import "../../mixins"

.nova-theme
    font-family: 'Muli', sans-serif

    .slider-amount-wrapper
        .total-input
            padding: 5px

        .cash-slider-currency
            padding: 5px

        .current-currency
            white-space: nowrap

        .cash-slider-top
            padding-right: 50px

        .input-item.currency .input-wrap
            display: flex

            label
                display: block
                width: 100%
                z-index: 1

            .arrow-wrapper
                display: block
                position: absolute
                right: 7px
                top: 11px


    .input
        padding: 5px

    .title-text
        span
            display: block

            &.no-deposit-free
                content: 'No deposit fee. no withdrawal fee.'
                display: block
                color: $cloud-gray
                font-size: $font-xs
                padding: 4px 0

    &.wrapper
        .wrap
            border: 1px solid transparent
            background-color: $dirtywhite
            padding: 0
            overflow: hidden

    .page-container
        padding: 0 20px 0 20px

        > .title-text
            display: none

        +media-max-width($breakpoint-699)
            padding: 0 10px 0 10px

    .result-container
        > .title-text
            display: block
            .polling-title
                .polling-loader
                    background: $dirtywhite
                    box-shadow: -15px 0 $dirtywhite, 15px 0 $dirtywhite
                    animation: shadowPulseNova 2s linear infinite

                    @keyframes shadowPulseNova
                        33%
                            background: $dirtywhite
                            box-shadow: -15px 0 $matgray, 15px 0 $dirtywhite
                        66%
                            background: $matgray
                            box-shadow: -15px 0 $dirtywhite, 15px 0 $dirtywhite
                        100%
                            background: $basic-white
                            box-shadow: -15px 0 $dirtywhite, 15px 0 $matgray

    .page-container.amount-page
        > .form
            margin: 20px 0 0 0
            padding: 0 20px 30px 20px
            +media-max-width($breakpoint-400)
                padding: 0 0 30px 0

    .row
        width: 100%
        display: flex
        justify-content: center
        align-items: flex-end
        flex-wrap: wrap

        &.row-col
            flex-wrap: nowrap

    .result-container
        color: $matgray
        font-size: $font-sm
        line-height: 20px
        padding-top: 15px
        display: flex
        flex-wrap: wrap
        flex-direction: column

        .transaction-status-wrapper
            span
                display: block

            td.declined, td.error, td.cancelled
                span
                    text-transform: uppercase
                    color: $ruby-red

            td.approved
                span
                    text-transform: uppercase
                    color: $spring-green

        .table
            display: block
            width: 100%

        .title-text
            div
                margin-bottom: 10px

    .page-container
        .back
            margin-bottom: 10px
            cursor: pointer
            display: block

            .arrow-wrapper
                position: absolute
                top: 3px
                left: 0
                transform: rotate(90deg)

                svg g path
                    fill: $basic-black

    .pci-container
        width: 100%
        margin-bottom: 0
        padding: 0

        .input-item
            margin-bottom: 0

            .pci-card-holder-input
                background-color: transparent
                color: $graphite-gray
                font-weight: 700
                border-bottom: 1px solid $transparent-black-25

                &[disabled]
                    background-color: $transparent-black-10
                    color: $transparent-charcoal-75
                    border-bottom: none
                    cursor: default

                &:focus
                    background-color: transparent

                &.has-error
                    border-bottom: 1px solid $ruby-red

            .pci-card-holder
                &.focus
                    .pci-card-holder-input
                        &.has-error
                            background-color: transparent
                            border-bottom: 1px solid $transparent-black-25

            .error
                color: $ruby-red

        #cards-list
            .multiple-options
                border-radius: 0
                box-shadow: none
                border: 1px solid $silver-gray

    .fee-container
        width: 100%

    .confirm-container .confirm-window
        .confirmation-required
            background-color: $spring-green

    .manual-bank-result-container
        .manual-bank-default-content
            .bank-details-title
                +media-max-width($breakpoint-699)
                    padding: 50px 0 10px

            h3, h4
                max-width: 520px

            .manual-bank-details-table
                border-collapse: collapse
                background-color: transparent
                max-width: 520px
                margin: 0 auto
                +media-max-width($breakpoint-699)
                    padding: 0

                td
                    border: 1px solid $transparent-slate-51

    .custom-select-container
        .custom-select-dropdown-arrow-container
            .svg-dropdown
                path
                    fill: $azure-blue

        .custom-select-options-container
            background-color: $basic-white
            border: 1px solid $silver-gray
            box-shadow: none
            padding: 0

            .custom-select-option
                font-size: $font-md
                transition: all 0.3s ease
                background-color: transparent
                color: $basic-black
                padding: 5px 10px 5px 15px

                &:hover
                    background-color: $electric-blue
                    color: $basic-white

                &.active
                    background-color: lighten($electric-blue, 10%)
                    color: $basic-white
