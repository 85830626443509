@import "../variables"
@import "../../../mixins"

.dark-theme
    .main-header
        border-bottom: 1px solid $transparent-cloudy-gray-30
        .header-profile-container
            .header-profile-toggle
                color: $current
                border-color: $current
                svg
                    fill: $current
            .header-profile-menu
                background-color: $charcoal-gray
        .header-logo
            .back
                color: $current
                opacity: 1
                font-size: $font-md
                font-weight: normal
                text-transform: uppercase
                +media-max-width($breakpoint-699)
                    font-size: $font-sm
            img
                width: 40px
        .header-customer
            color: $basic-white
            font-weight: 100
            +media-max-width($breakpoint-699)
                font-size: $font-sm
        .main-method
            color: $concrete-gray
            +media-max-width($breakpoint-699)
                font-size: $font-2xs
        .header-username
            color: $current
            text-decoration: none
            opacity: 1
            &:hover
                text-decoration: underline
                opacity: 1
