@import "../../../@cashier-project/assets/styles/mixins"
@import "../../../shared-styles"

.button, a.button
    margin: 0 auto
    padding: 0 10px
    text-decoration: none
    text-transform: uppercase
    font-weight: bold
    font-family: inherit
    font-size: $font-md
    cursor: pointer
    outline: none
    color: $basic-white
    background-color: $sky-blue
    border: none
    min-width: 185px
    max-width: 100%
    width: fit-content
    height: 40px
    letter-spacing: 1px
    white-space: nowrap
    +center
    text-align: center
    overflow: hidden
    +tr(0.25)
    border-radius: 3px
    opacity: 1
    &:hover
        opacity: 0.8
    &:focus
        outline: none
    &.disabled
        opacity: 0.7
        &:hover
            opacity: 0.5
    &.button-outline
        background-color: transparent
        border: 2px solid $sky-blue
        color: $sky-blue
