@import "variables"
@import "../../mixins"

.dark-theme
    font-family: 'Open Sans', sans-serif
    color: $basic-white
    .loader-container
        &.fixed
            background-color: $charcoal-gray
        background-color: $transparent-black-50
        &.non-transparent
            background-color: $charcoal-gray
    &.wrapper
        background-color: $charcoal-gray
        min-height: 300px
        height: auto
        +media-max-width($breakpoint-800)
            padding: 0
        .wrap
            box-shadow: 0 8px 16px $transparent-black-12
            border-radius: 12px
            background-color: $dull-black
            +tr(0.25)
            max-width: 100%
            width: 922px
            min-height: auto
            padding: 15px 30px
            +media-max-width($breakpoint-800)
                min-height: 100vh
                box-shadow: none
                border: 0
                border-radius: 0
                padding: 15px 15px 30px
            +media-max-width($breakpoint-400)
                padding: 15px 10px 20px
            +media-max-width($breakpoint-360)
                padding: 15px 8px 20px
            .pay-method
                display: flex
                margin-top: 15px
    .form-container form
      padding: 0
    footer
        padding: 0
        font-size: $font-5xs
        color: $concrete-gray
        .content
            color: $concrete-gray
        +media-max-width($breakpoint-699)
            padding: 0 5px
        .copyright
            font-size: $font-4xs
            color: $concrete-gray
    .title-text .arrow-wrapper
        display: none
    .custom-html
        display: flex
        width: 100%
        justify-content: center
        .pay-fields-container
            padding: 10px 20px!important
    .slick-prev:before,.slick-next:before
        color: $transparent-black-15
    .logo-title
        justify-content: flex-start
        .logo-title-img
            display: block
    .custom-html
        .pay-fields-container
            width: 100%
            max-width: 100%
            padding: 0
    .transactions-page
        .transactions-page-title
            color: $basic-white
        .transactions-table,.transactions-list
            td,th
                text-align: center
                color: $basic-white
                border: 1px solid $transparent-misty-white-10
    .fee-container
        background-color: $slate-gray
        color: $basic-white
        box-shadow: none
        margin: 10px 7px 0 7px
        +media-max-width($breakpoint-699)
            margin: 0 10px
        .total
            color: $basic-white
    &.virtual_terminal
        .convert-component
            .convert-limits-container
                    .checkbox-container
                        color: $basic-white
        .fee-container
            margin: 0 7px 0 7px
            +media-max-width($breakpoint-699)
                margin: 0 10px
    .multiple-select-container
        .multiple-options
            background-color: lighten($charcoal-gray, 5%)
            color: $basic-white
    .custom-select-container
        .custom-select-dropdown-arrow-container
            .svg-dropdown
                path
                    fill: $concrete-gray
        .custom-select-options-container
            background-color: lighten($charcoal-gray, 5%)
            color: $basic-white
            .custom-select-option.active
                color: $current
    .pci-container
        #secure-card-number, #secure-exp, #secure-cvv,#secure-card-holder, .pci-card-holder-input
            border-radius: 4px
            background-color: $charcoal-gray
            font-size: $font-md
            color: $basic-white
            +tr(0.25)
        .pci-card-holder-input
            border: 1px solid $basic-white
            padding: 1px 5px 0 15px
            &:focus
                background-color: $charcoal-gray
                border: 1px solid $current
            &[disabled]
                opacity: 0.6
                border: 1px solid $neutral-gray
            &.has-error
                border: 1px solid red
                color: red
        .pci-card-holder
            &.focus
                .pci-card-holder-input
                    &.has-error
                        background-color: $charcoal-gray
                        border: 1px solid $current
                        color: $basic-white
        .input-item
            .error
                color: red

    .confirm-container
        .confirm-window
            background-color: $charcoal-gray
            .confirmation-required
                background-color: $current
                color: $basic-black
    .transactions-pending-table .not-transactions-found
        color: $basic-white
    .arrow-wrapper
        svg
            g path
                fill: $current
