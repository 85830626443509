@import "../variables"
@import "../../../mixins"

.supreme-theme
    .modal-container
        background-color: $transparent-white-90
        .modal
            border-radius: 8px
            box-shadow: 0 4px 8px 0 $transparent-black-30
            +media-max-width($breakpoint-699)
                padding: 20px 10px 30px
            .input-item
                .input
                    color: gray
                    border-radius: 5px
                    border: 1px solid $charcoal-gray
                    opacity: 0.8
                &.has-error
                    .input
                        border: 1px solid $crimson-red
            .credit-add-container
                +media-max-width($breakpoint-699)
                    padding: 5px 0
                .error
                    color: $crimson-red
            .input-item
                margin: 0
                padding: 0
                .title
                    color: inherit
                .input
                    color: inherit
                    &.read-only:focus
                        background-color: $purple
            .close, .close-button
                background-color: $coral
                &:hover
                    background-color: darken($coral, 5%)
            .modal-header
                margin-bottom: 10px
                .modal-title
                    color: $coral
            .save-button-container
                display: flex
                +media-max-width($breakpoint-320)
                    transform: translate(0, 5px)
                .button.save-button
                    flex-grow: initial
                    height: 40px
                    font-size: $font-xl
                    +media-max-width($breakpoint-320)
                        transform: translate(0, 0)
                    +media-max-width($breakpoint-290)
                        min-width: 170px
        &.billing-info-modal
            .input-item
                margin: 4px 10px
                padding: 0
                +media-max-width($breakpoint-320)
                    margin: 4px 0
        &.promocode-modal, &.transactions-modal
            .close-button::after, .close-button::before
                display: none
        &.transactions-modal
            .confirm-window
                .confirmation-required
                    background-color: $coral
        &.promocode-modal
            .promocode
                max-width: 60%
                +media-max-width($breakpoint-699)
                    max-width: 100%
.supreme-theme.prxqr-modal
    max-width: 710px
    height: calc(100% - 30px)
    .prxqr-primary-color
        color: $coral
    .prxqr-primary-color svg path
        stroke: $coral
