@import "../variables"
@import "../../../mixins"

.classic
    .main-header
        +justifySpaceBetween
        height: 50px
        border-bottom: 2px solid $slate-blue
        padding: 2px 5px 7px
        .header-profile-container .header-profile-toggle
            color: $slate-blue
            border-color: $slate-blue
            svg
                fill: $slate-blue
        .header-logo
            .back
                font-weight: bold
                color: $slate-blue
                padding: 10px 0
                display: flex
                align-items: center
                .arrow-wrapper
                    transform: rotate(90deg)
                    margin: 5px 0 5px 0
                    height: 12px
                    svg g path
                        fill: $slate-blue
                img
                    width: 12px
                    fill: red
            .logo-container
                +center
            img
                width: 50px
                +media-max-width($breakpoint-400)
                    width: 40px
        .main-method
            color: $slate-blue
            font-size: $font-md
            text-transform: uppercase
            font-weight: bold
            width: 100%
            padding: 0 15px
            +media-max-width($breakpoint-400)
                font-size: $font-sm
                padding: 0 10px
        .header-customer
            white-space: nowrap
            +media-max-width($breakpoint-400)
                font-size: $font-sm
        .header-username
            text-decoration: underline
            color: $charcoal-gray
            cursor: pointer
            padding: 5px
            &:hover
                color: lighten($basic-black, 40%)
    .title-container
        text-align: center
        position: relative
        .logo-title
            +center
        .title-text
            font-size: $font-md
            .logo-title-img
                display: inline
.main-header.header-virtual_terminal
    background-color: $cherry-red
    padding: 10px
    position: relative
    color: $basic-white
    border-bottom: none
    .header-logo
        img
            width: 100%
            height: 100%
            display: block
        .back
            color: $basic-white
            opacity: 1
            .arrow-wrapper svg g path
                fill: $basic-white
            span
                color: $basic-white
        .main-method
            color: $basic-white
    .main-method
        color: $basic-white
        padding: 0 5px
    .header-customer
        z-index: 1
        font-size: $font-sm
        display: flex
        align-items: center
        .title-wrapper
            display: flex
            align-items: center
            color: $basic-white
        .header-username
            color: $basic-white
            &:after
                display: none
        i
            padding: 0 5px 0 0
            color: $yellow
        +media-max-width($breakpoint-400)
            font-size: $font-2xs
