@import "../variables"
@import "../../../mixins"

.supreme-theme
    .symbol-error
        display: none
    .input-item
        color: $charcoal-gray
        .title
            color: $basic-white
            opacity: 1
            margin-bottom: 3px
        .input-wrap
            .input
                border-radius: 5px
                background-color: $basic-white
                color: $charcoal-gray
                &::placeholder
                    color: $silverdark
                &.read-only, &[disabled]
                    background-color: $silver
                    color: $transparent-black-50
                    opacity: 1
                &:focus
                    background-color: $light-blue
                    box-shadow: 0 0 5px $basic-white
            .input-icon
                top: 45%
        .error
            color: $crimson-red
            +tr(0.25)
        &.focus
            .title
                color: $basic-white
            .input
                background-color: $light-blue
                border-bottom: 1px solid $sky-blue
                box-shadow: 0 0 5px $basic-white
        &.has-error
            .error
                color: $yellow
            .input
                border: 1px solid $yellow
                background-color: $light-pink
            .title
                color: $yellow
