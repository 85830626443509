@import "../variables"
@import "../../../mixins"

.nova-theme
    .modal-container
        background-color: $transparent-white-90
        .modal
            .modal-header
                margin-bottom: 10px
                .modal-title
                    color: $azure-blue
                    +media-max-width($breakpoint-699)
                        margin-bottom: 15px
                .button
                    max-width: 220px
            .button
                margin: 10px auto
        &.profile-modal
            .modal-header
                margin-left: 7px
    .close, .close-button
        background-color: $azure-blue
        &:hover
            background-color: darken($azure-blue, 5%)
.nova-theme.prxqr-modal
    .prxqr-modal-content
        border-radius: 0
    .prxqr-primary-color
        color: $azure-blue
    .prxqr-primary-color svg path
        stroke: $azure-blue
