@import "../variables"

.nova-theme
    .checkbox-container
        font-size: $font-md
        .checkbox-label
            border-color: $spring-green
        .checkbox-input
            &:checked ~ .checkbox-label
                background-color: $spring-green
        .checkbox-label
            height: 25px
            width: 25px
            min-width: 25px
            min-height: 25px
