@import "../../mixins"

.billing-info-container
    min-width: 100%
    +padding
    margin: 8px 0 0 0
    max-width: 100%
    .billing-info
        color: $charcoal-gray
        border: 1.5px solid $charcoal-gray
        height: 35px
        font-size: $font-sm
        border-radius: 3px
        min-width: 180px
        width: fit-content
        max-width: 100%
        overflow: hidden
        white-space: nowrap
        cursor: pointer
        margin-bottom: 5px
        padding: 0 10px
        text-transform: uppercase
        +center
        font-weight: bold
        &:hover
            opacity: 0.8
            +tr(0.25)
        +media-max-width($breakpoint-699)
            padding: 5px
            font-size: $font-2xs
        +media-max-width($breakpoint-270)
            font-size: $font-4xs
    .billing-info-error
        color: $crimson-red
        display: flex
        padding: 0
        font-size: $font-sm
        align-items: center
        flex-direction: row
        margin-bottom: 10px
        +media-max-width($breakpoint-699)
            font-size: $font-2xs
        .billing-info-error-icon
            width: 20px
            min-width: 20px
            margin: 0 7px
            display: flex
            align-items: center
