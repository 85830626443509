@import "../mixins"
@import "../../../../shared-styles"

html, body
    font-family: 'Open Sans', sans-serif
    margin: 0
    -webkit-appearance: none
html, body
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar
        width: 0
    &::-webkit-scrollbar-thumb
html
    overflow-y: auto
*
    box-sizing: border-box
.cause-an-error
    display: none!important
svg.checked-svg
    width: 20px
    height: 20px
    margin: 0 5px
a
    text-decoration: none
    color: inherit
    &.customer-link
        text-decoration: underline
        &:hover
            opacity: 0.9
            text-decoration: none
p
    margin: 0

html[lang="he"], html[lang="ar"]
    direction: rtl
    #cards-list
        .multiple-option:not(.new-card-option)
            display: flex!important
            direction: ltr
            justify-content: end
select:disabled
    opacity: 1

.red-text
    color: $crimson-red

svg
    height: 100%
    width: 100%

.note-error
    color: $crimson-red
    margin: 0 3px
.title-text
    text-align: center
    padding: 25px 10px 15px
    font-size: $font-md
    +media-max-width($breakpoint-699)
        font-size: $font-sm
    .logo-title
        +center
    .logo-title-img
        width: 40px
        height: 25px
        margin-right: 5px
        margin-left: 5px
        object-fit: contain
        display: none
select:disabled
    opacity: 1

.hide-block
    display: none!important

.wrapper
    width: 100%
    padding: 15px
    display: flex
    justify-content: center
    align-items: flex-start
    position: relative
    +media-max-width($breakpoint-400)
        padding: 5px
    .wrap
        max-width: 620px
        min-height: 300px
        border: 1px solid $transparent-black-10
        border-top: 0
        padding: 30px
        width: 620px
        background-color: white
        height: auto
        position: relative
        +media-max-width($breakpoint-400)
            padding: 10px 5px
            width: 100%

.col-wrapper
    width: 100%

footer
    padding-top: 10px
    padding-bottom: 10px
    margin-top: 20px
    white-space: pre-line
    .content-bold
        font-size: $font-2xs
        color: $transparent-black-50
        line-height: 1.5
        font-weight: bold
        margin-top: 20px
        margin-bottom: 2px
    .content
        font-size: $font-4xs
        color: $transparent-black-60
        line-height: 1.5
        span.customer-link
            text-transform: lowercase
.copyright
    font-size: $font-4xs
    color: $slate-blue
    text-align: center
    margin-top: 15px
.row
    width: 100%
    display: flex
    justify-content: center
    align-items: flex-start
    &.with-templates
        .templates
            width: 160px
    &.convert-component
        .currency
            width: 200px

.table
    border-collapse: collapse
    tr
        td
            font-size: $font-sm
            border: 1px solid $transparent-black-10
            padding: 7px 20px
            color: $transparent-black-60

.close-button
    width: 30px
    height: 30px
    position: relative
    cursor: pointer
    +tr(0.25)

.iframe-redirect-container
    +center
    padding: 20px 10px

.redirect-iframe
    border: 0

.form-container
    form
        width: 100%
        padding: 15px 5px
        box-sizing: border-box
        +center
        flex-direction: column
.stop-redirect-button
    padding: 30px 0

.pay-fields-container
    .html-add-fields
        width: 100%
        padding: 0 10px 20px 10px
.credit-add-container
    width: 100%

.test-c
    display: flex
    .test-c-block
        margin: 10px
.arrow-wrapper
    width: 15px
    height: 15px
    cursor: pointer
    display: inline-block
    svg
        width: 100%
        height: 100%
        g path
            fill: $azure-blue
.back
    .arrow-wrapper
        transform: rotate(90deg)
