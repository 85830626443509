@import "../variables"
@import "../../../mixins"

.dark-theme
    .page-container.pay-method
        .numpad-wrap
            color: $basic-black
        .method-wrapper
            padding: 20px
            box-shadow: 0 2px 4px $transparent-black-20
            border-radius: 6px
            background-color: $charcoal-gray
            margin: 0
            width: 100%
            +media-max-width($breakpoint-900)
                padding: 20px 10px
            +media-max-width($breakpoint-699)
                padding: 0 5px
                border: none
                margin-top: 20px
            .title-container
                .title-text
                    padding: 0
                    margin-bottom: 0
                    font-weight: 300
                    text-transform: uppercase
                    color: $basic-white
                    letter-spacing: 0.7px
                    +media-max-width($breakpoint-699)
                        padding: 25px 10px 15px
                        .logo-title-img
                            display: inline

            .col-wrapper
                .html-add-fields
                    margin-bottom: 15px
                    margin-top: 10px
                .credit-add-container
                    padding: 15px 0
                    display: flex
                    flex-wrap: wrap
                    .row
                        width: 50%
                    +media-max-width($breakpoint-699)
                        .row
                            width: 100%

html[lang="he"], html[lang="ar"]
    direction: rtl
    .dark-theme
        .page-container.pay-out-page, .page-container.pay-in-page, .page-container.pay-method
            .amount-input-container
                .current-currency
                    right: auto
                    left: 5px
