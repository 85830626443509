@import "variables"
@import "../../mixins"

.supreme-theme
    font-family: 'Open Sans', sans-serif
    color: $charcoal-gray
    .loader-container
        .loader
            background-image: url("https://assets.praxispay.com/images/Cashier_cubes_preloader.gif")
    &.wrapper
        min-height: 300px
        height: auto
        +media-max-width($breakpoint-699)
            padding: 0
            background-image: url("../../../images/bg_light_supreme.svg")
            background-size: contain
        .wrap
            box-shadow: 0 4px 8px 0 $transparent-black-30
            +tr(0.25)
            max-width: 100%
            width: 710px
            min-height: auto
            border-radius: 8px
            padding: 15px 30px
            +media-max-width($breakpoint-800)
                min-height: 100vh
                box-shadow: none
                border: 0
                padding: 15px 15px 30px
                background: transparent
            +media-max-width($breakpoint-400)
                padding: 15px 10px 20px
            +media-max-width($breakpoint-360)
                padding: 15px 8px 20px
        .slider-container
            background-color: $soft-gray
    .checkbox-accept-container
        .checkbox-error
            color: $yellow
    .form-container form
        padding: 0 5px
    .title-container
        color: $ocean-blue
    .slider-container .slick-slider .slider-item .img
        border: 1px solid $silver-blue
    .slider-container .slick-slider .slider-item.current-slide .img
        box-shadow: 0 2px 4px $transparent-black-50
        border: none
        border-radius: 5px
    footer
        padding: 0 30px
        margin: 40px 0 0 0
        font-size: $font-5xs
        color: $neutral-gray
        +media-max-width($breakpoint-699)
            padding: 0 5px
        .copyright
            font-size: $font-4xs
            color: $neutral-gray
    .custom-html
        display: flex
        width: 100%
        justify-content: center
        .pay-fields-container
            padding: 10px 20px!important
    .title-text
        text-transform: uppercase
        color: $charcoal-gray
        letter-spacing: 0.7px
        font-weight: 400
        .arrow-wrapper
            display: none
    .slick-prev:before,.slick-next:before
        color: $transparent-black-15
    .custom-html
        .pay-fields-container
            display: flex
            flex-direction: column
            width: 100%
            max-width: 100%
            padding: 0
            color: $basic-white
    .transactions-page
        .transactions-page-title
            color: $basic-white
        .new-transaction
            +media-max-width($breakpoint-699)
                font-size: $font-md
                height: 45px
        .table
            tr
                td, th
                    color: $basic-white
                    border: 1px solid $transparent-pearl-51
                    +media-max-width($breakpoint-699)
                        font-size: $font-4xs
                    a
                        +media-max-width($breakpoint-699)
                            font-size: $font-4xs
    .transaction-status-wrapper
        td.declined, td.error, td.cancelled
            span
                text-transform: uppercase
                color: $crimson-red
        td.approved
            span
                text-transform: uppercase
                color: $emerald-green
        .fee-container
            margin: 5px 10px 5px 10px
    .multiple-select-container
        .multiple-separator
            background-color: $transparent-foggy-gray-50
        .multiple-options
            background-color: $light-blue
        .multiple-dropdown-container
            .svg-dropdown
                path
                    fill: $transparent-foggy-gray-50
            &:hover
                background-color: $transparent-pearl-50
    .custom-select-container
        .custom-select-dropdown-arrow-container
            .svg-dropdown
                path
                    fill: $transparent-foggy-gray-50
            &:hover
                background-color: $transparent-pearl-50
        .custom-select-options-container
            top: 40px
            background-color: $light-blue
            border-radius: 5px

    .pci-container
        .pci-card-holder-input
            &:focus
                background-color: $light-blue
            &[disabled]
                background-color: $silver
                color: $transparent-black-50
                opacity: 1
            &.has-error
                border: 1px solid $yellow
                background-color: $light-pink
        .pci-card-holder
            &.focus
                .pci-card-holder-input
                    &.has-error
                        background-color: $light-blue
                        border: none
        .input-item
            .error
                color: $yellow
    .arrow-wrapper
        svg
            g path
                fill: $coral
