@import "../../assets/styles/variables"
@import "../../../shared-styles" 
   
.panoramic-2-0-theme
    .footer
        display: flex
        justify-content: center
        flex-direction: column
        gap: 4px
        color: var(--neutral_active)
        font-size: $font-xs-relative
        padding: 0
        margin: 0
        +media-max-width($breakpoint-480)
            font-size: $font-2xs-relative
        .footer-content
            display: flex
            flex-direction: column
            gap: 24px
            text-align: center
            font-size: inherit
            color: inherit
            +media-max-width($breakpoint-480)
                text-align: left
            .custom-footer-links
                display: flex
                justify-content: center
                align-items: center
                height: 30px
                gap: 24px
                flex-wrap: wrap
                +media-max-width($breakpoint-600)
                    gap: 16px
                +media-max-width($breakpoint-480)
                    justify-content: flex-start
                a
                    color: inherit
                    font-size: inherit
                    &:hover
                        color: var(--primary_active)
        .copyright
            text-align: center
            margin: 0
            padding: 0
            font-size: inherit
            font-family: inherit
            gap: 4px
            color: inherit
            +media-max-width($breakpoint-480)
                text-align: left

