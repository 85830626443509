@import "../variables"
@import "../../../mixins"

.classic
    .page-container.retry-page-container
        .amount-block
            .input-item.currency
                .input-wrap .input
                    background-color: transparent

        .button-container
            .button
                min-width: 160px
                