@import "../../../shared-styles"

.loader-container
    position: absolute
    top: 0
    bottom: 0
    right: 0
    left: 0
    background-color: $transparent-white-50
    z-index: 100
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    padding: 15px
    &._small-loader
        position: relative
        background-color: transparent
        padding: 0
        display: inline
        .loader
            position: absolute
            top: 0
            left: 0
            right: 0
            bottom: 0
            margin: auto
            width: 20px
            height: 20px
    &.loader-delay:not(._small-loader)
        background-color: $transparent-white-90
        .loader-delay-text
            color: $charcoal-gray
            font-size: $font-md
            text-align: center
            padding-top: 10px
    &.non-transparent
        background-color: $basic-white
        z-index: 300
    &.initial-loading-text
        .loader-text
            animation: fadeOut 4s forwards
            -webkit-animation: fadeOut 4s forwards
            -moz-animation: fadeOut 4s forwards
            -o-animation: fadeOut 4s forwards
    @keyframes fadeOut
        from
            opacity: 1
        to
            opacity: 0
    +media-max-width($breakpoint-400)
        padding: 5px
    .loader-text
        padding: 10px
        margin-bottom: 20px
        font-size: $font-3xl
    .loader
        width: 50px
        height: 50px
        background-image: url("https://assets.praxispay.com/images/Cashier_cubes_preloader.gif")
        background-size: contain
    &.fixed
        position: fixed
        z-index: 1
        background-color: $basic-white
    &.index
        z-index: 2

@keyframes preloader
    to
        transform: rotate(360deg)
