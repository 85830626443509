@import "../../../mixins"
$amount-width: 230px

.supreme-theme
    .col-wrapper
        .pay-fields-container
            max-width: calc(100% - #{$amount-width})
        .amount-wrapper
            min-width: $amount-width
            width: $amount-width
    .col-wrapper
        display: flex
        flex-wrap: wrap
        +media-max-width($breakpoint-699)
            flex-direction: column
        .pay-fields-container
            padding: 0 30px 0 0
            width: 100%
            +media-max-width($breakpoint-699)
                width: 100%
                max-width: none
                padding: 0
        .amount-wrapper
            padding: 0
            +media-max-width($breakpoint-699)
                padding: 0
                width: 100%
                min-width: auto
    .method-wrapper
        .auth-form
            .col-wrapper
                .pay-fields-container
                    .row
                        padding: 0 7px
                .amount-wrapper
                    .btn-wrapper
                        width: 100%
                        max-width: 100%
                        right: 0
                        left: 0
                        bottom: -25px
