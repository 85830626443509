@import "../../../mixins"

.nova-theme
    .manual-bank-wire-page
        .manual-bank-wire-page-title
            width: 100%
            height: 20px
            margin-bottom: 10px
            font: normal normal normal 18px/23px Muli
            letter-spacing: 0px
            color: $metal-gray

        .bank-items-container
            max-width: 100%
            .bank-items-list
                max-height: 60vh
                +media-max-width($breakpoint-699)
                    max-height: 50vh

        .amount-wrapper
            width: 100%
            max-width: 100%
            margin-top: 30px

        .bank-item
            background: $basic-white 0% 0% no-repeat padding-box
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16) // TODO: replace with color variable
            border-radius: 5px
            border: 1px solid transparent

            &.selected
                border: 1px solid $spring-green

            .title
                font: normal normal bold 19px/24px Muli
                letter-spacing: 0px
                color: $graphite-gray

            .selected-icon
                background-color: $spring-green

                .checkbox-arrow-svg
                    fill: $basic-white