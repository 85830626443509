@import "../variables.sass"
.nova-theme
    .page-container.basic-open-banking-page
        padding: 40px 20px 25px

        .open-banking-content
            .open-banking-option:hover
                border-color: $spring-green

            .open-banking-option.selected
                border-color: $spring-green

            .button-container
                .button
                    min-width: 160px