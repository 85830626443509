@import "../variables"
@import "../../../mixins"

.default-theme
    .modal-container
        background-color: $transparent-white-90
        .modal
            border-radius: 8px
            box-shadow: 0 4px 8px 0 $transparent-black-30
            +media-max-width($breakpoint-699)
                padding: 20px 10px 30px
            .modal-header
                margin-bottom: 10px
            .credit-add-container
                +media-max-width($breakpoint-699)
                    padding: 5px 0
            .input
                color: $charcoal-gray
            .close, .close-button
                background-color: $sky-blue
                &:hover
                    background-color: darken($sky-blue, 5%)
            .modal-header
                .modal-title
                    color: $sky-blue
            .save-button-container
                display: flex
                .button.save-button
                    flex-grow: initial
        &.billing-info-modal
            .input-item
                margin: 4px 10px
                padding: 0
                +media-max-width($breakpoint-320)
                    margin: 4px 0
        &.promocode-modal, &.transactions-modal
            .close-button::after, .close-button::before
                display: none
.default-theme.prxqr-modal
    max-width: 800px
