@import "../../../mixins"
@import "../../../../../../shared-styles"

.classic
    .gateways-container
        .gateway-item
            +tr(0.25)
            .img
                +tr(0.25)
            .title
                opacity: 0.7
                +tr(0.25)
                +media-max-width($breakpoint-400)
                    font-size: $font-sm
            &:hover
                .img
                    box-shadow: 0 0 7px 0 $transparent-black-50
                    transform: scale(1.02)
                .title
                    opacity: 1
