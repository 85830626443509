//font-sizes
@import "./_font-size"
    
//Theme editor settings START
$border-style: var(--border_style)
$font-family: var(--font_family)
//Theme editor settings END

$font_size: var(--font_size)
$input-height: 40px

//shadows
$shadow_color: var(--shadow_color)
$shadow_xxl: 0 18px 40px 0 color-mix(in srgb, var(--shadow_color) 36%, transparent)
$shadow_xl: 0 18px 40px 0 color-mix(in srgb, var(--shadow_color) 28%, transparent)
$shadow_m: 0 12px 32px 0 color-mix(in srgb, var(--shadow_color) 24%, transparent)
$shadow_s: 0 10px 24px 0 color-mix(in srgb, var(--shadow_color) 16%, transparent)
$shadow_xs: 0 4px 24px 0 color-mix(in srgb, var(--shadow_color) 16%, transparent)
$shadow_xxs: 0 8px 32px 0 color-mix(in srgb, var(--shadow_color) 12%, transparent)

//colors
$pano-2-0-slate-gray: #2B303B
$pano-2-0-light-gray: #e7e9ee
$pano-2-0-bright-blue: #0CA1D7FF