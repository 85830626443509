@import "../variables"
@import "../../../mixins"
.supreme-theme
    .gateways-container
        .gateway-item
            width: 150px
            +media-max-width($breakpoint-360)
                width: 140px
            +media-max-width($breakpoint-315)
                width: 120px
            +media-max-width($breakpoint-275)
                width: 115px
            .title
                padding: 12px 0
                font-size: $font-2xs
                font-weight: 400
                text-align: center
                margin: 0
            .img
                background-color: $basic-white
                border-radius: 5px
                height: 90px
                +tr(0.25)
                box-shadow: 0 1px 2px $transparent-black-20
                +media-max-width($breakpoint-315)
                    height: 70px
                img
                    +tr(0.25)
            &:hover
                .img
                    box-shadow: 0 1px 2px 0 $transparent-black-40
