@import "../variables"
@import "../../../mixins"

.supreme-theme
  .main-header
    border-bottom: 1px solid $silver-blue
    .header-logo
      .back
        color: $coral
        font-size: $font-md
        font-weight: normal
        text-transform: uppercase
        +media-max-width($breakpoint-699)
          font-size: $font-sm
      img
        width: 40px
    .header-customer
      color: $neutral-gray
      +media-max-width($breakpoint-699)
        font-size: $font-sm
    .main-method
      color: $sky-blue
      +media-max-width($breakpoint-699)
        font-size: $font-2xs
    .header-username
      color: $sky-blue
      text-decoration: none
      opacity: 1
      &:hover
        text-decoration: underline
        opacity: 1
