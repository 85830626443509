@import "../../../mixins"

.supreme-theme
    .manual-bank-wire-page
        .manual-bank-wire-page-title
            width: 100%
            height: 20px
            font: normal normal normal 15px/20px Open Sans
            letter-spacing: 0
            color: $basic-white
            margin-top: 2px
            margin-bottom: 3px

        .bank-items-container
            padding: 0 30px 0 0

            +media-max-width($breakpoint-699)
                width: 100%
                max-width: none
                padding: 0
                margin-bottom: 15px

        .amount-wrapper
            .title
                font: normal normal 600 15px/20px Open Sans
                letter-spacing: 0px
                color: $basic-white
                height: auto

            .input
                height: 50px

        .bank-item
            background: $basic-white 0% 0% no-repeat padding-box
            border-radius: 8px
            border: 2px solid $basic-white

            &.bank-item-warning
                .icon-container
                    display: none

            &.selected
                background: $ash-gray 0% 0% no-repeat padding-box
                border: 2px solid $coral

            .title
                font: normal normal normal 18px/24px Open Sans
                letter-spacing: 0
                color: $charcoal-gray

            .selected-icon
                background-color: $sapphire-blue

                .checkbox-arrow-svg
                    fill: $basic-white