@import "../variables"

.dark-theme
    .input-item
        color: $charcoal-gray
        .title
            color: $basic-white
            border-radius: 3px
            opacity: 1
            transform: translate(6px, 10px)
            z-index: 2
            background-color: $charcoal-gray
            display: inline-block
            position: relative
            padding: 0 3px
        .title-none
            background-color: transparent
        .input-wrap
            .input-icon
                border-radius: 3px
                padding: 1px
                img
                    background-color: $basic-white
                    padding: 2px
                    border-radius: 2px
            .input
                border: 1px solid $basic-white
                border-radius: 4px
                background-color: $charcoal-gray
                color: $basic-white
                &::placeholder
                    color: $dark-theme-light-gray
                &.read-only, &[disabled]
                    background-color: $charcoal-gray
                    opacity: 0.6
                    border: 1px solid $neutral-gray
                &:focus
                    background-color: $charcoal-gray
                    border: 1px solid $current
        .error
            color: $basic-white
            background-color: $crimson-red
            border-radius: 3px
            display: flex
            align-items: center
            padding: 0 3px
        &.focus
            .title
                color: $current
                opacity: 1
            .input
                border: 1px solid $current
                background-color: $charcoal-gray
                &::placeholder
                    opacity: 0
        &.has-error
            .error
                color: $basic-white
                background-color: $crimson-red
            .input
                border: 1px solid $crimson-red
                color: $crimson-red
                background-color: $charcoal-gray
            .title
                color: $basic-white
                background-color: $crimson-red
