$font-6xs: 7px
$font-5xs: 9px
$font-4xs: 10px
$font-3xs: 11px
$font-2xs: 12px
$font-xs: 13px
$font-sm: 14px
$font-md: 16px
$font-lg: 17px
$font-xl: 18px
$font-2xl: 19px
$font-3xl: 20px
$font-4xl: 21px
$font-5xl: 22px
$font-6xl: 24px
$font-7xl: 26px
$font-8xl: 28px
$font-9xl: 29px
