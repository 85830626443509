@import "../variables"

.supreme-theme
    .checkbox-container
        color: $basic-white
        .checkbox-label
            border-color: $basic-white
            .checkbox-arrow-svg
                fill: $basic-black
        .checkbox-input
            &:checked ~ .checkbox-label
                background-color: $basic-white
    .checkbox-content
        .checkbox-input
            &:checked ~ .checkbox-label
                background-color: $basic-white
