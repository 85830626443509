@import "../variables"

.supreme-theme
    .window-redirect-container
        background: url("../../../../images/bg_supreme.png") $dullblue no-repeat bottom
        background-size: contain
        .content-text,
        .title-text
            color: $basic-white
        .button
            height: 45px
            font-size: $font-md
        &.polling-middle-page
            .loader-container
                background-color: transparent