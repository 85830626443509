@import "../variables"
@import "../../../mixins"

.supreme-theme
    .page-container.retry-page-container
        .amount-block
            .title
                color: $charcoal-gray
            .input
                border: 1px solid $transparent-black-10
