@import "../variables"
@import "../../../mixins"

.nova-theme
    .page-container.retry-page-container
        padding: 40px 20px 25px
        .amount-block
            .slider-amount-wrapper
                padding: 0
                .input-item.currency
                   display: none
        .button-container
            .button
                min-width: 160px