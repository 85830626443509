@import "variables"

.classic
    color: $charcoal-gray

    .note-error
        color: $scarlet-red

    .confirm-container .confirm-window .confirmation-required
        background-color: $slate-blue

    .title-text
        color: $slate-blue

        .arrow-wrapper
            display: none

    .pci-container
        width: 100%
        padding: 0

        form
            padding: 15px 0

            #secure-exp, #secure-cvv, #secure-card-number, #secure-card-holder, .pci-card-holder-input
                background-color: $ghost-white
                box-sizing: border-box
                outline: none
                height: 35px

            .pci-card-holder-input
                padding: 1px 8px 0 8px
                border: 1px solid $platinum-gray

                &[disabled]
                    background-color: $transparent-black-10
                    color: $transparent-charcoal-80
                    border: none
                    -webkit-user-select: none
                    -ms-user-select: none
                    user-select: none
                    cursor: default
                    opacity: 1

                &:focus
                    border-bottom: 1px solid $slate-blue
                    background-color: $ghost-white

                &.has-error
                    border: 1px solid $scarlet-red

            .pci-card-holder
                &.focus
                    .pci-card-holder-input
                        &.has-error
                            border-bottom: 1px solid $slate-blue
                            background-color: $ghost-white

            .input-item
                .error
                    color: $scarlet-red

        #cards-list
            .multiple-options
                border-radius: 0
                box-shadow: none
                border: 1px solid $platinum-gray

    .custom-select-container
        .custom-select-dropdown-arrow-container
            .svg-dropdown
                path
                    fill: $transparent-foggy-gray-50

        .custom-select-options-container
            background-color: $basic-white
            border-radius: 0
            box-shadow: none
            border: 1px solid $platinum-gray
