@import "../variables"
@import "../../../mixins"

.dark-theme
    .modal-container
        background-color: $transparent-black-63
        .modal
            box-shadow: 0 4px 8px 0 $transparent-black-30
            background-color: $charcoal-gray
            border-radius: 8px
            +media-max-width($breakpoint-699)
                padding: 20px 10px 30px
            .modal-header
                margin-bottom: 10px
            .credit-add-container
                +media-max-width($breakpoint-699)
                    padding: 5px 0
            .input-item
                margin: 0
                .title
                    color: $basic-white
                .input
                    color: $basic-white
                &.has-error
                    .title
                        color: $basic-white
                        background-color: $crimson-red
                        display: flex
                        align-items: center
            .close, .close-button
                background-color: $current
                path
                    fill: $basic-black
                &:hover
                    background-color: darken($current, 5%)
                    opacity: 1
            .close-button:before,
            .close-button:after
                background-color: $basic-black
            .modal-header
                .modal-title
                    color: $basic-white
                    font-weight: 600
            .save-button-container
                display: flex
                .button.save-button
                    flex-grow: initial
                    margin: 10px auto
        &.transactions-modal .modal
            .transactions-pending-table
                &::-webkit-scrollbar
                    width: 5px
                    height: 5px
                &::-webkit-scrollbar-track
                    background-color: $charcoal-gray
                    border-radius: 10px
                &::-webkit-scrollbar-thumb
                    background-color: $current
                    border-radius: 10px
                .transactions-list
                    td, th
                        color: $basic-white
                        border-color: $basic-white
                        text-align: center
        &.billing-info-modal
            .input-item
                margin: 4px 10px
        &.promocode-modal, &.transactions-modal
            .close-button::after, .close-button::before
                display: none
.dark-theme.prxqr-modal
    background-color: $transparent-black-63
    color: $basic-white
    max-width: 922px
    .prxqr-modal-content
        box-shadow: 0 4px 8px 0 $transparent-black-30
        background-color: $charcoal-gray
        border-radius: 8px
    .prxqr-primary-color
        color: $current
    .prxqr-primary-color svg path
        stroke: $current
    .prxqr-close svg path
        stroke: $basic-white
