@import "../variables"

.nova-theme
    .window-redirect-container
        padding: 50px 20px
        .title-text
            display: block
            color: $matgray
        .content-text
            color: $matgray
        &.polling-middle-page
            .loader-container
                background-color: transparent