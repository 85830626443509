@import "../../../mixins"

.default-theme
    .manual-bank-wire-page

        .manual-bank-wire-page-title
            width: 100%
            height: 20px
            font: normal normal normal 13px/18px Open Sans
            letter-spacing: 0
            color: $metal-gray
            margin-bottom: 10px

        .bank-items-container
            padding: 10px 30px 10px 10px

            +media-max-width($breakpoint-699)
                width: 100%
                max-width: none
                padding: 10px 5px

        .amount-wrapper
            // title mb:10 + h:20 = 30
            margin-top: 30px

        .bank-item
            background: $basic-white 0% 0% no-repeat padding-box
            border-radius: 4px
            border: 1px solid $default-theme-blue

            &.selected
                background: $smoke-gray 0% 0% no-repeat padding-box
                border: 1px solid $default-theme-red

            .title
                font: normal normal normal 17px/23px Open Sans
                letter-spacing: 0
                color: $metal-gray

            .selected-icon
                background-color: $sapphire-blue

                .checkbox-arrow-svg
                    fill: $basic-white