@import "../../../shared-styles"

.transactions-pending-table
    & + .transactions-status-container
        margin-top: 10px
        .delete-transaction-error
            font-size: $font-sm
            color: $crimson-red
            margin-top: 10px
            opacity: 0.7
        .success-transaction
            font-size: $font-sm
            color: green
            margin-top: 20px
            opacity: 1
            display: flex
            align-items: center
            svg
                flex-shrink: 0
    .not-transactions-found
        padding: 20px
        color: $transparent-black-50
        font-size: $font-md
    table
        width: 100%
        thead
            tr
                &:hover
                    background-color: inherit
                th
                    border: 1px solid $transparent-black-10
                    background-color: transparent
                    font-size: $font-sm
                    padding: 5px 10px
        tr
            &:hover
                background-color: $transparent-black-02
        th
            font-weight: normal
            background-color: $transparent-black-10
            padding: 5px 2px
            font-size: $font-2xs
            &.transactions-page-title
                background-color: transparent
            +media-max-width($breakpoint-360)
                font-size: $font-4xs
        td
            padding: 7px 10px
            font-size: $font-2xs
            text-align: center
            +media-max-width($breakpoint-900)
                padding: 7px 4px
            +media-max-width($breakpoint-370)
                padding: 7px 4px
                text-align: center
            +media-max-width($breakpoint-360)
                font-size: $font-4xs
            .button.deleteTransactionBtn
                min-width: 50px
                margin: 0 auto
                font-size: $font-4xs
                height: 25px
                padding: 0 5px
                box-shadow: none
                max-width: 100px
                color: $crimson-red
                background-color: transparent
                border: 1px solid $crimson-red
                letter-spacing: normal
                +media-max-width($breakpoint-360)
                    font-size: $font-4xs
                    padding: 0 1px
                    min-width: 35px
                    height: 20px
                &:hover
                    background-color: $crimson-red
                    color: $basic-white
                    svg
                        fill: $basic-white
                svg
                    width: 13px
                    height: 13px
                    margin: 0 3px 0 0
