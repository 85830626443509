$basic-white: #FFFFFF
$ghost-white: #FAFAFA

$basic-black: #000000
$dull-black: #222222

$neutral-gray: #777777
$charcoal-gray: #333333
$pale-gray: #cccccc
$ash-gray: #EDEDED
$soft-gray: #efefef
$graphite-gray: #343434
$platinum-gray: #DDDDDD
$cloud-gray: #a5a5a5
$pearl-gray: #E7E7E7
$metal-gray: #575757
$silver-gray: #A2A2A2
$medium-gray: #808080
$concrete-gray: #cacaca
$smoke-gray: #F5F5F5
$nickel-gray: #C3C3C3
$slate-gray: #3D3D3D
$dark-gray: #373737
$steel-gray: #999999
$granite-gray: #8a8a8a
$mist-gray: #A3A3A3
$frost-gray: #F6F6F6
$mellow-gray: #BABABA
$shadow-gray: #646464
$light-smoke-gray: #EEEEEE
$light-pearl-white: #FBFBFB
$pale-moon-gray: #E8E9E5
$midnight-gray: #939393
$slate-mid-gray: #717171
$pearl-grayish-white: #F3F4F6
$cloudy-silver-gray: #E8EAEE

$spring-green: #82BB41
$emerald-green: #088001
$emerald-sea-green: #308C67
$pale-aqua-green: #A3F2CF

$sky-blue: #0CA1D7
$pale-blue: #F7FBFF
$ocean-blue: #1A577A
$silver-blue: #d9e1e8
$light-blue: #eef5fb
$sapphire-blue: #2270a7
$azure-blue: #1187F4
$electric-blue: #1E90FF
$powder-blue: #ebf0fc
$slate-blue: #4980aa
$deep-sapphire-blue: #0F509E
$azure-ocean-blue: #1399CD
$midnight-navy-blue: #0B253A
$cerulean-blue: #1084F0
$steel-blue: #254158

$tangerine-orange: #F89600

$crimson-red: #ff0000
$cherry-red: #D20400
$ruby-red: #dc143c
$scarlet-red: #CF0000
$coral-red: #ff4444
$firebrick-red: #F44336

$light-pink: #FFF6F6

$transparent-black-02: rgba(0, 0, 0, 0.02)
$transparent-black-07: rgba(0, 0, 0, 0.07)
$transparent-black-10: rgba(0, 0, 0, 0.10)
$transparent-black-15: rgba(0, 0, 0, 0.15)
$transparent-black-16: rgba(0, 0, 0, 0.16)
$transparent-black-20: rgba(0, 0, 0, 0.20)
$transparent-black-25: rgba(0, 0, 0, 0.25)
$transparent-black-30: rgba(0, 0, 0, 0.30)
$transparent-black-40: rgba(0, 0, 0, 0.40)
$transparent-black-50: rgba(0, 0, 0, 0.50)
$transparent-black-60: rgba(0, 0, 0, 0.60)
$transparent-black-70: rgba(0, 0, 0, 0.70)
$transparent-black-80: rgba(0, 0, 0, 0.80)

$transparent-white-10: rgba(255, 255, 255, 0.10)
$transparent-white-50: rgba(255, 255, 255, 0.50)
$transparent-white-80: rgba(255, 255, 255, 0.80)
$transparent-white-90: rgba(255, 255, 255, 0.90)

$transparent-foggy-gray-10: rgba(51, 51, 51, 0.10)

$transparent-slate-30: rgba(165, 165, 165, 0.30)
$transparent-slate-51: rgba(165, 165, 165, 0.51)

$transparent-obt-yellow: rgba(255, 203, 76, 0.15)

$day-primary-2: #DAF5FB
$night-main-white: #F2F2F2