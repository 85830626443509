@import "../../../mixins"
@import "../variables"
.classic
    .amount-wrapper
        width: 100%
        padding: 0
    .fee-container
        color: $charcoal-gray
        margin: 2px 7px
    .convert-component
        +center
        flex-direction: column
        .row
            flex-direction: row
            flex-wrap: wrap
            .input-item
                width: 50%
            .current-currency
                padding: 0 10px
