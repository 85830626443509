@import "../../mixins"

.no-add-fields
    flex-direction: column
    .amount-wrapper
        margin: 0 auto
.amount-wrapper
    display: flex
    flex-direction: column
    flex-wrap: wrap
    border-radius: 10px
    padding: 10px
    width: 260px
    height: fit-content
    margin: 0 auto
    order: 2
    +media-max-width($breakpoint-699)
        flex-direction: row
        padding: 10px 0
        width: 100%
        max-height: none
    +media-max-width($breakpoint-270)
        min-width: 250px
    .convert-component
        .row
            flex-direction: column
.amount-input-container
    position: relative
    .current-currency
        position: absolute
        top: 50%
        transform: translateY(-50%)
        right: 5px
        font-size: $font-2xs
        padding: 0

.amount-limits-component
    font-size: $font-4xs
    color: $medium-gray
    +padding
    margin-bottom: 5px
    line-height: 1.6
    max-width: 100%
    overflow: hidden
    width: 100%
    .limits-interval
        margin: 0 3px
        white-space: nowrap
        max-width: 100%
    .limits-container
        padding-left: 8px
