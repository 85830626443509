@import "../variables"
@import "../../../mixins"

.default-theme
    .main-header
        border-bottom: 2px solid $transparent-black-05
        .header-logo
            .back
                color: $sky-blue
                font-size: $font-md
                font-weight: normal
                text-transform: uppercase
                +media-max-width($breakpoint-699)
                    font-size: $font-sm
            img
                width: 40px
        .header-customer
            color: $neutral-gray
            +media-max-width($breakpoint-699)
                font-size: $font-sm
        .main-method
            color: $sky-blue
            +media-max-width($breakpoint-699)
                font-size: $font-2xs
        .header-username
            color: $sky-blue
            text-decoration: none
            opacity: 1
            &:hover
                text-decoration: underline
                opacity: 1
