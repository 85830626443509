@import "../variables"


.classic
    .input-item
        .symbol-error.red-text
            padding: 0 2px
        .title
            color: $slate-blue
            padding: 0 0 0 10px
            margin: 0 0 5px 0
            opacity: 1
        .error
            color: $scarlet-red
        .input
            border: 1px solid $platinum-gray
            background-color: $ghost-white
            color: $charcoal-gray
            font-size: $font-sm
            &:focus
                border-bottom: 1px solid $slate-blue
            &.read-only, &[disabled]
                background-color: $transparent-black-10
                color: $transparent-charcoal-80
                border: none
                user-select: none
                cursor: default
                opacity: 1
                &:focus
                    border-bottom: none
            &::placeholder
                font-size: $font-2xs
                opacity: 0.7
                font-weight: normal
            +media-max-width($breakpoint-360)
                font-size: $font-2xs
        .input-wrap .input-icon i
            color: $transparent-black-40
        .input-wrap .input-icon .cvv-icon:hover i
            color: $transparent-black-40
    .focus
        .input
            border-bottom: 1px solid $slate-blue
    .has-error
        .input
            border: 1px solid $scarlet-red
        .title
            color: $scarlet-red
