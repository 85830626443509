@import "../../../@cashier-project/assets/styles/mixins"
@import "../../../shared-styles"

.modal-container
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    background-color: $transparent-black-50
    z-index: 101
    display: flex
    justify-content: center
    padding: 10px 10px 30px
    overflow-y: auto
    .close-button
        z-index: 2
    .modal
        width: 550px
        margin: 15px auto
        border-radius: 3px
        padding: 20px 20px 30px
        max-width: 90%
        background-color: $basic-white
        position: relative
        height: fit-content
        box-shadow: 0 0 10px $transparent-black-20
        .modal-header
            display: flex
            justify-content: space-between
            width: 100%
            margin-bottom: 30px
            align-items: center
            .modal-title
                font-size: $font-3xl
                color: $slate-blue
                width: 100%
                text-align: center
                +media-max-width($breakpoint-699)
                    font-size: $font-xl
                +media-max-width($breakpoint-400)
                    font-size: $font-md
            +media-max-width($breakpoint-699)
                margin-bottom: 15px
        .input-item
            min-height: 0px
            margin-top: 5px
            margin-bottom: 5px
        .close, .close-button
            width: 35px
            height: 35px
            position: absolute
            top: 0
            right: 0
            transform: translate(50%, -50%)
            display: flex
            justify-content: center
            align-items: center
            border-radius: 100%
            cursor: pointer
            transition: 0.25s all ease
            svg
                height: 17px
                width: 17px
            .close-button:before,
            .close-button:after
                background-color: $basic-white
                height: 17px

.prxqr-modal
   max-width: 620px
   height: calc(100% - 30px)
   background-color: $transparent-white-80
