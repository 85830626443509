@import "../../../mixins"

.dark-theme
    .billing-info-container
        .billing-info
            border-radius: 3px
            color: $basic-black
            background-color: $basic-white
            border: 1px solid $basic-white
        .billing-info-error
            color: $crimson-red
